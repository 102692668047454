<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%; display: flex; align-items: center">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px; padding-left: 20px"
          size="mini"
        >
          <el-form-item label="施工单位:">
            <el-input v-model="searchModel.cusName"></el-input>
          </el-form-item>
          <el-form-item label="工程名称:">
            <el-input v-model="searchModel.projName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="submitSearch"
              :loading="loading"
            >{{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-close"
              v-if="loading"
              @click="loading = false"
            >停止</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <BaseTable
        id="proMixTab"
        ref="xTable1"
        size="mini"
        border
        auto-resize
        resizable
        highlight-current-row
        highlight-hover-row
        export-config
        :align="allAlign"
        :seq-config="{
          startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
        }"
        :data="tableData"
      >
        <vxe-table-column type="seq" title="序号" width="60" />
        <!--   <vxe-table-column field="projNum" title="编号" show-overflow="title" min-width="135" show-header-overflow="title"/>-->
        <vxe-table-column
          field="projName"
          title="工程名称"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="cusName"
          title="施工单位"
          show-overflow="title"
          show-header-overflow="title"
          min-width="60"
        />
        <vxe-table-column
          field="constructionPoint"
          title="部位"
          show-overflow="title"
          min-width="60"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="strengthGrade"
          title="强度等级"
          show-overflow="title"
          min-width="120"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="betonLeak"
          title="抗渗等级 "
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="techneed"
          title="其他技术要求"
          show-overflow="title"
          min-width="60"
          show-header-overflow="title"
        />
        <vxe-table-column
          field="slumps"
          title="施工坍落度"
          min-width="60"
          show-overflow="title"
          show-header-overflow="title"
        />
        <!--        <vxe-table-column
            field="supplyWeight"
            title="石子最大粒径(m³) "
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="printNum"
            title="设计坍落度"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price1"
            title="每m³原材料用量(干重)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price1"
            title="含水率（砂、石）"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="price1"
            title="每m³原材料用量(湿重)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />-->
      </BaseTable>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
    </div>
  </PageContainer>
</template>

<script>
import { getProdMixManList } from '@/apis/quality/productMixMan'

export default {
  name: 'productMixMan',
  data () {
    return {
      searchModel: {
        cusName: '',
        projName: ''
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      }
    }
  },
  methods: {
    handleItemChange () {},
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      getProdMixManList({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    }
  },
  mounted () {
    this.refreshData()
  }
}
</script>s

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
