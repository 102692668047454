var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContainer',[_c('div',{staticClass:"layout"},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center"}},[_c('el-form',{staticStyle:{"margin-top":"20px","padding-left":"20px"},attrs:{"inline":true,"model":_vm.searchModel,"size":"mini"}},[_c('el-form-item',{attrs:{"label":"施工单位:"}},[_c('el-input',{model:{value:(_vm.searchModel.cusName),callback:function ($$v) {_vm.$set(_vm.searchModel, "cusName", $$v)},expression:"searchModel.cusName"}})],1),_c('el-form-item',{attrs:{"label":"工程名称:"}},[_c('el-input',{model:{value:(_vm.searchModel.projName),callback:function ($$v) {_vm.$set(_vm.searchModel, "projName", $$v)},expression:"searchModel.projName"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","loading":_vm.loading},on:{"click":_vm.submitSearch}},[_vm._v(_vm._s(_vm.searchText)+" ")])],1),_c('el-form-item',[(_vm.loading)?_c('el-button',{attrs:{"type":"primary","icon":"el-icon-close"},on:{"click":function($event){_vm.loading = false}}},[_vm._v("停止")]):_vm._e()],1)],1)],1),_c('BaseTable',{ref:"xTable1",attrs:{"id":"proMixTab","size":"mini","border":"","auto-resize":"","resizable":"","highlight-current-row":"","highlight-hover-row":"","export-config":"","align":_vm.allAlign,"seq-config":{
        startIndex: (_vm.tablePage.currentPage - 1) * _vm.tablePage.pageSize,
      },"data":_vm.tableData}},[_c('vxe-table-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-table-column',{attrs:{"field":"projName","title":"工程名称","min-width":"60","show-overflow":"title","show-header-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"cusName","title":"施工单位","show-overflow":"title","show-header-overflow":"title","min-width":"60"}}),_c('vxe-table-column',{attrs:{"field":"constructionPoint","title":"部位","show-overflow":"title","min-width":"60","show-header-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"strengthGrade","title":"强度等级","show-overflow":"title","min-width":"120","show-header-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"betonLeak","title":"抗渗等级 ","min-width":"60","show-overflow":"title","show-header-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"techneed","title":"其他技术要求","show-overflow":"title","min-width":"60","show-header-overflow":"title"}}),_c('vxe-table-column',{attrs:{"field":"slumps","title":"施工坍落度","min-width":"60","show-overflow":"title","show-header-overflow":"title"}})],1),_c('vxe-pager',{attrs:{"loading":_vm.loading,"current-page":_vm.tablePage.currentPage,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.total,"layouts":[
        'Sizes',
        'PrevPage',
        'JumpNumber',
        'NextPage',
        'FullJump',
        'Total' ]},on:{"update:currentPage":function($event){return _vm.$set(_vm.tablePage, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.tablePage, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.tablePage, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.tablePage, "pageSize", $event)},"page-change":_vm.handlePageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }